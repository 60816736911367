import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from "./Contact.module.scss"
import { contactInfo } from '../../Config/config'
import emailjs from '@emailjs/browser';
import Alert from '@mui/material/Alert';
import ReCAPTCHA from "react-google-recaptcha";
export default class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      number: '',
      message: '',
      errors: {},
      showAlert: false  // Initialize showAlert state variable
    };
    this.form = React.createRef();
    this.sendEmail = this.sendEmail.bind(this);
  }
  
  validateForm = () => {
    const { name, email, number, message } = this.state;
    const errors = {};
  
    // Validate name
    if (!name.trim()) {
      errors.name = 'Name is required';
    }
  
    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      errors.email = 'Invalid email address';
    }
  
    // Validate number (assuming it should be numeric and not empty)
    if (!number.trim() || !/^\d+$/.test(number)) {
      errors.number = 'Invalid phone number';
    }
  
    // Validate message
    if (!message.trim()) {
      errors.message = 'Message is required';
    }
  
    this.setState({ errors });
    return Object.keys(errors).length === 0; // Return true if there are no errors
  };
  
  sendEmail = (e) => {
    e.preventDefault();
  
    if (this.validateForm()) {
      emailjs.sendForm('service_hrt3xho', 'template_b2ce9mp', this.form.current, 'dmnY5YrYWdy55Lj2P')
        .then((result) => {
          console.log(result.text);
          this.setState({ showAlert: true });
          // Reset form fields after successful submission if needed
          this.setState({
            name: '',
            email: '',
            number: '',
            message: '',
            errors: {}
          });
          setTimeout(() => {
            this.setState({ showAlert: false });
          }, 3000);
        }, (error) => {
          console.log(error.text);
        });
    }
  };
  
  // Update state as the user types in the input fields
  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  handleRecaptchaChange = (value) => {
    // Handle the reCAPTCHA value change
    console.log("reCAPTCHA value:", value);
  }
  render() {
    const { name, email, number, message, errors ,showAlert} = this.state;
    return (
      <div>
        <section className={styles.contact}>
                <div className='container'>
                    <div className={styles.bnr_info}>
                        <h2>Qream Solutions</h2>
                        <h1>Contact Us</h1>
                        <p><a href='/'>Home</a></p>
                    </div>
                </div>
        </section>
        <section className={styles.contact_forms}>
            <div className='container'>
                <div className={styles.wrap}>
                    <div className={styles.cont_info}>
                        {contactInfo.map((ele)=>{
                            return(
                                <div>
                                    <h1>{ele.head}</h1>
                                    <h2>{ele.subhed}</h2>
                                    <ul>
                                        {ele.infos.map(i=><div className={styles.list_wrap}><i className={i.icon}></i><li>{i.info}</li></div>)} 
                                    </ul>
                                </div>
                            )
                        })}
                    </div>
                    <form ref={this.form} onSubmit={this.sendEmail}>
                        <div className={styles.box}>
                            <label>Full Name </label>
                            <input type="text" name="name" value={name} onChange={this.handleInputChange} placeholder="Name" />
                            <p>{errors.name && <span>{errors.name}</span>}</p>
                        </div>
                        <div className={styles.box}>
                            <label>Email </label>
                            <input type="email" name="email" value={email} onChange={this.handleInputChange} placeholder="Email" />
                            <p>{errors.email && <span>{errors.email}</span>}</p>
                        </div>
                        <div className={styles.box}>
                            <label>Mobile No </label>
                            <input type="text" name="number" value={number} onChange={this.handleInputChange} placeholder="Phone Number" />
                            <p>{errors.number && <span>{errors.number}</span>}</p>
                        </div>
                        <div className={styles.box}>
                            <label>Comment or Message </label>
                            <textarea name="message" value={message} onChange={this.handleInputChange} placeholder="Message"></textarea>
                            <p>{errors.message && <span>{errors.message}</span>}</p>
                        </div>
                  
                          <ReCAPTCHA  style={{marginBottom:"1.5rem"}}
                            sitekey="6LfREQopAAAAAKQuJw8RatSZ3LgSE-kaZbbnhAid"
                            onChange={this.handleRecaptchaChange}
                          />
                        <button type='submit'>Submit</button>
                          {showAlert ? (
                            <Alert sx={{ width: '100%', textAlign:'center',justifyContent:"center", marginTop:'2rem' ,borderBottom:"3px Solid green",}} severity="success" color="info">
                              Thank you, {this.name}! We have received your mail.
                            </Alert>
                          ) : null}
                    </form>
                </div>
         
            </div>
            
        </section>
      </div>
    )
  }
}
