import React, { Component } from 'react'
import styles from "./Navbar.module.scss"
import { navbarMenu } from '../../Config/config'
import { NavHashLink as Link } from 'react-router-hash-link';

export class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      colorChange: false,
    };
    this.myFunction = this.myFunction.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.changeNavbarColor);
  }

  componentWillUnmount() {

    window.removeEventListener("scroll", this.changeNavbarColor);
  }
 

  myFunction() {
    console.log("button click");
    this.setState((prevState) => ({
      showMenu: !prevState.showMenu,
    }));
    document.body.classList.toggle('no-scroll');
  }

  changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      this.setState({
        colorChange: true,
        
      });
    } else {
      this.setState({
        colorChange: false,
      });
    }
  };
  scrollWidthOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80; 
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
  }
  
  render() {

    return (
      <div>
        <nav className={`${styles.navbar} ${this.state.colorChange ? styles.colorChange : ""}`}>
          <div className='container'>
            <div className={styles.nav_wrap}>
              <div className={styles.logo}>
                <Link to="/">
                  <img src='assets/logo2.png' alt='logo' />
                </Link>
              </div>
              <div className={styles.menu}>
                <ul>
                  {navbarMenu.map((ele) => {
                    return (
                      <li>
                        <Link  to={ele.url} exact activeClassName='active' scroll={(el) => this.scrollWidthOffset(el)}> {ele.name} </Link >
                         {ele.dropDwon && (
                          <div className={styles.dropdown_content}>
                                <ul >
                                  {Object.values(ele.dropDwon).map((item, index) => {
                                    return (
                                      <div className={styles.items}>
                                        <li >
                                          <div className={styles.brp_menu}>
                                            <img src={item.img} />
                                            <Link key={index} to={item.url} scroll={(el) => this.scrollWidthOffset(el)}>{item.name}</Link>
                                          </div>
                                        </li>
                                      </div>
                                    );
                                  })}
                                </ul>
                          </div>
                        )}
                      </li>
                    )
                  })}
 
                </ul>
              </div>
              <div className={styles.contactus}>
                <Link to="/contact"> <i class="fa-solid fa-envelope"></i> Contact Us</Link>
              </div>
            </div>
          </div>
        </nav>

        <header className={`${styles.mobile_nav} ${this.state.colorChange ? styles.colorChange : ""}`}>
          <nav>
            <div className="container">
              <div className={styles.nav_bar}>
                <div className={styles.logo}>
                  <Link to="/">
                    <img src="assets/logo2.png" alt="" />
                  </Link>
                </div>
                <div className={styles.toggle_button}>
                  <button
                    onClick={this.myFunction}
                    className={this.state.showMenu ? styles.menuicons : ""}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </button>
                </div>
              </div>
            </div>
          </nav>
        </header>

        {/* {this.state.showMenu && ( */}
        <div className={`${styles.toggle_menu}  ${this.state.showMenu ? styles.actives : ""}`} >
          <div className={styles.menus}>
            <ul className={styles.navbar_nav}>
                {navbarMenu.map((ele) => {
                    return (
                      <li>
                        <Link  to={ele.url} onClick={this.myFunction} scroll={(el) => this.scrollWidthOffset(el)}  className={this.state.showMenu ? styles.menuicons : "nav_a"}>{ele.name}</Link > 
                      </li>
                    )
                  })}
                  <li><Link to="/contact" onClick={this.myFunction}  className={this.state.showMenu ? styles.menuicons : "nav_a"}>Contact us</Link></li>
            </ul>

          </div>

        </div>
      </div>
    )
  }
}

export default Navbar



