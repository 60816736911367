import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from "./About.module.scss"
import { aboutInfo, team,qream_data } from '../../Config/config'
export class About extends Component {
  static propTypes = {

  }

  render() {
    return (
        <div>
            <section className={styles.about}>
                <div className='container'>
                    <div className={styles.bnr_info}>
                        <h2>Qream Solutions</h2>
                        <h1 data-aos="fade-up" data-aos-duration="1300">About us</h1>
                        {/* <p>We help businesses to build and scale their own dedicated teams at a competitive price in India</p> */}
                        <p><a href='/'>Home</a></p>
                    </div>
                </div>
            </section>
            <section className={styles.about_info}>
                <div className='container'>
                    <div className={styles.wrap}>
                    {aboutInfo.map((ele)=>{
                        return(
                            <div className={styles.items} data-aos="flip-left" data-aos-duration="1300">
                                <img src={ele.img} alt=''/>
                                <div className={styles.info}>
                                    <h4>{ele.name}</h4>
                                    <p>{ele.info}</p>
                                </div>
                            </div>
                        )
                    })}

                    </div>
                </div>
            </section>

            <section className={styles.why_qrm}>
            <div className='container'>
                <div className={styles.heading}>
                <h1>Why Qream</h1>
                </div>
                <div className={styles.wrap}>
                    {qream_data.map((ele)=>{
                    return(
                        <div className={styles.item} data-aos="flip-left" data-aos-once="true"  data-aos-duration="1000">
                            <img src={ele.img} alt=''/>
                            <h2>{ele.name}</h2>
                        <ul>
                        {ele.point.map((i)=>{
                            return(
                            <li><i className="fa-solid fa-caret-right"></i>{i.li}</li>
                            )
                        })}
                        </ul>
                        </div>
                    )
                    })}
                </div>

            </div>
            </section>

            <section className={styles.team}>
                    <div className='container'>
                        <div className={styles.heading}>
                            <h1> Team</h1>
                            
                        </div>
                        <div className={styles.wrap}>
                                {team.map((ele)=>{
                                    return(
                                        <div className={styles.card}data-aos="flip-up" data-aos-duration="1300">
                                   
                                            <div className={styles.card_body}>
                                                <h2>{ele.name}</h2>
                                                <h3>{ele.subTitel}</h3>
                                                <ul>
                                                    {ele.info.map(i=><li><i className="fa-solid fa-caret-right"></i><p> {i.infoLi}</p></li>)}
                                                    
                                                </ul>
                                            </div>
                                        </div>
                                    )
                                })}
                        </div>
                        <div className={styles.team_subheadig}>
                            <ul>
                                <li><img src='assets/icons/check (2).png' alt=''/> Team is backed by a Leadership team composed of senior industry experts  with a proven track record of international success</li>
                                <li><img src='assets/icons/check (2).png' alt=''/> We have the strategic guidance and global insights needed to thrive in diverse markets</li>
                                <li><img src='assets/icons/check (2).png' alt=''/> This, coupled with our substantial financial backing, positions us to capitalize on opportunities for rapid and sustainable growth in the industry</li>
                            </ul>
                        </div>
                    </div>
            </section>
        </div>
    )
  }
}

export default About
