import React, {  useEffect, useRef, useState} from 'react'
import styles from "./Home.module.scss"
import { Link, } from 'react-router-dom'
import {  bnrInfo,  heroSec,  services, solutionMobile, solutions, solutionsTwo, useCase } from '../../Config/config'
import Slider from "react-slick";
import { HashLink, NavHashLink } from 'react-router-hash-link';
import Navbar from '../Navbar/Navbar';
import { NavLink } from 'react-router-dom';
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className="nextArrow" onClick={onClick}><i class="fa-solid fa-arrow-right"></i></div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
   
    <div className="prevArow" onClick={onClick}><i class="fa-solid fa-arrow-left"></i></div>
  );
}
export function Home() {

  var solution = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  var service = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  
  };

    const [solutionContent, setSolutionContent] = useState('Healthcare Solutions'); 
    const clicks = (content) => {
      setSolutionContent(content);
      const solutionExplainSection = document.getElementById('solutionexplain');
    
      if (solutionExplainSection) {
        const { top } = solutionExplainSection.getBoundingClientRect();
        const offsetTop = window.pageYOffset + top - 70;
    
        window.scrollTo({
          top: offsetTop,
          behavior: 'smooth',
        });
        const solutionExplainSectionTop = solutionExplainSection.getBoundingClientRect().top + window.pageYOffset;
    
        if (solutionExplainSectionTop < window.pageYOffset) {
          window.scrollTo({
            top: solutionExplainSectionTop,
            behavior: 'smooth',
          });
        }
      } 
    };

    const solutionsContent = () => {
      switch (solutionContent) {
        case 'Healthcare Solutions':
          return (
            <>
            <div className={styles.fadeUp} id='helth'>
                  <ul>
                    <li data-aos="fade-up" data-aos-duration="1000"> 
                      <div>
                        <ul >
                          <li>
                            <div className={styles.infos} >
                              <img src= "assets/icons/checked.png" alt=""/>
                              <p>Empowering Healthcare Providers with implified workflows with enhanced collaboration for</p>
                            </div>
                             
                            <ul>
                              <li>
                                <div className={styles.infos}>
                                  <img src= "assets/icons/checked (1).png" alt=""/>
                                  <p>State-of-the-art telemedicine platforms connecting patients with healthcare providers remotely</p>
                                </div>
                              </li>
                              <li>
                                <div className={styles.infos}>
                                  <img src= "assets/icons/checked (1).png" alt=""/>
                                  <p>  Advanced diagnostic tools enabling medical assessments </p>
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li data-aos="fade-up" data-aos-duration="1500"> 
                      <div>
                        <ul>
                          <li > 
                            <div className={styles.infos}>
                              <img src= "assets/icons/checked.png" alt=""/>
                              <p> Our team has crafted solutions to empower patients to have seamless access to healthcare resources and personalized treatment plans</p>
                            </div>
                          </li>
                        </ul>
                      
                      </div>
                    </li>
                    <li data-aos="fade-up" data-aos-duration="2000"> 
                    <div>
                      <ul>
                        <li>
                        <div className={styles.infos}>
                            <img src= "assets/icons/checked.png" alt=""/>
                            <p>  Solutions to enable continuous care support facilitating the providers and administrators to make informed decisions, operational efficiency, enhancing quality of care</p>
                          </div>
                          </li>
                      </ul>
                    
                    </div>
                    </li>
                  
                  </ul>
              </div>
          </>
            
          );
        case 'Technology Solutions':
          return (
            <>
            <div className={styles.fadeUp} id='tech'>
                   <ul> 
                      <li data-aos="fade-up" data-aos-duration="1000"> 
                          <div>
                            <ul>
                              <li>
                              <div className={styles.infos}>
                                <img src= "assets/icons/checked.png" alt=""/>
                                <p> Your aspirations are our inspiration.</p>
                              </div>
                                 
                                <ul>
                                  <li>
                                    <div className={styles.infos}>
                                      <img src= "assets/icons/checked (1).png" alt=""/>
                                      <p>Whether you’re a startup aiming to disrupt the market or an established enterprise seeking digital evolution - We listen, understand and then innovate</p>
                                    </div>
                                  </li>

                                </ul>
                              </li>
                            </ul>
                          </div>
                      </li>
                      <li data-aos="fade-up" data-aos-duration="1500"> 
                      <div>
                        <ul>
                          <li>
                            <div className={styles.infos}>
                              <img src= "assets/icons/checked.png" alt=""/>
                              <p> By speaking your language, we create solutions that resonate with your goals, enabling you to stay agile, competitive and prepared </p>
                            </div>
                             
                          </li>
                        </ul>
                      
                      </div>
                      </li>
                      <li data-aos="fade-up" data-aos-duration="2000"> 
                          <div>
                            <ul>
                              <li>
                                <div className={styles.infos}>
                                <img src= "assets/icons/checked.png" alt=""/>
                                <p>Our commitment to your success doesn’t end with implementation </p>
                                </div>
                                

                                <ul>
                                  <li> 
                                  <div className={styles.infos}>
                                <img src= "assets/icons/checked (1).png" alt=""/>
                                <p> We provide continuous support, real-time monitoring and proactive maintenance ensuring you remain ahead of the curve</p>
                                </div>
                                 
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </div>
                      </li>
                  </ul>
              </div>
            </>
          
          );
        case 'ERP Solutions':
          return (
            <>
              <div className={styles.fadeUp}>
                  <ul> 
                      <li data-aos="fade-up" data-aos-duration="1000"> 
                          <div>
                            <ul>
                              <li>
                              <div className={styles.infos}>
                                <img src= "assets/icons/checked.png" alt=""/>
                                <p>Customer-Centric Approach</p>
                                </div>
                                 
                                 </li>
                            </ul>
                          </div>
                      </li>
                      <li data-aos="fade-up" data-aos-duration="1500"> 
                      <div>
                        <ul>
                          <li> 
                          <div className={styles.infos}>
                                <img src= "assets/icons/checked.png" alt=""/>
                                <p> As every business is unique, Let your ERP solution should reflect your vision</p>
                          </div>
                            
                            <ul>
                              <li>
                              <div className={styles.infos}>
                                <img src= "assets/icons/checked (1).png" alt=""/>
                                <p> We blend our expertise with your aspirations, creating a bespoke ERP system that aligns perfectly with your goals. </p>
                              </div>
                                 </li>
                              <li>
                              <div className={styles.infos}>
                                <img src= "assets/icons/checked (1).png" alt=""/>
                                <p> Our dedicated team of professionals collaborates closely with you to understand your requirements, ensuring that your ERP solution is your pathway to success</p>
                              </div>
                                 </li>

                            </ul>
                          </li>
                        </ul>
                      
                      </div>
                      </li>
                      <li data-aos="fade-up" data-aos-duration="2000"> 
                          <div>
                            <ul>
                              <li>
                              <div className={styles.infos}>
                                <img src= "assets/icons/checked.png" alt=""/>
                                <p> Streamline entire operation for elevated performance by aligning your goal with ERP solution - Supply chain and logistics, Manufacturing, Inventory</p>
                          </div>
                               </li>
                            </ul>
                          </div>
                      </li>
                  </ul>
              </div> 
            </>
          );
        case 'Custom Solutions':
          return (
            <>
            <div className={styles.fadeUp}>
                  <ul> 
                      <li data-aos="fade-up" data-aos-duration="1000"> 
                          <div>
                            <ul>
                              <li>
                              <div className={styles.infos}>
                                <img src= "assets/icons/checked.png" alt=""/>
                                <p> Ready to transform your ideas into digital solutions ?</p>
                              </div>
                                  
                                <ul>
                                  <li>
                                  <div className={styles.infos}>
                                <img src= "assets/icons/checked (1).png" alt=""/>
                                <p>  Our customized solutions cover a wide spectrum, from responsive user interfaces to complex backend algorithms </p>
                              </div>
                                    </li>
                                </ul>
                              </li>
                            </ul>
                          </div>
                      </li>
                      <li data-aos="fade-up" data-aos-duration="1500"> 
                      <div>
                        <ul>
                          <li>
                          <div className={styles.infos} >
                                <img src= "assets/icons/checked.png" alt=""/>
                                <p> We blend creativity and technical prowess, ensuring that your solution not only meets industry standards but also stands out in the digital landscape</p>
                              </div>
                          </li>
                        </ul>
                      
                      </div>
                      </li>
                      <li data-aos="fade-up" data-aos-duration="2000"> 
                          <div>
                            <ul>
                              <li>
                              <div className={styles.infos}>
                                <img src= "assets/icons/checked.png" alt=""/>
                                <p> We specialize in understanding your concept, delving into the intricacies of your requirements, and transforming your vision into a functional, intuitive and captivating reality</p>
                              </div>
                                
                                </li>
                            </ul>
                          </div>
                      </li>
                  </ul>
            </div>
          </>
          );
      }
    };

    const [servicesContent, setServicesContent] = useState('Web App Development'); // State to track selected content
    const click = (content) => {
      setServicesContent(content);
      const serviceExplainSection = document.getElementById('serviceexplain');
    
      if (serviceExplainSection) {
        const { top } = serviceExplainSection.getBoundingClientRect();
        const offsetTop = window.pageYOffset + top - 70;
    
        window.scrollTo({
          top: offsetTop,
          behavior: 'smooth',
        });
        const serviceExplainSectionTop = serviceExplainSection.getBoundingClientRect().top + window.pageYOffset;
    
        if (serviceExplainSectionTop < window.pageYOffset) {
          window.scrollTo({
            top: serviceExplainSectionTop,
            behavior: 'smooth',
          });
        }
      } 
    };

    const serviceContent = () => {
      switch (servicesContent) {
        case 'Web App Development':
          return (
            <>
            <div>
                  <ul>
                    <li data-aos="fade-up" data-aos-duration="1000"> 
                      <div className={styles.infos}>
                        <img src= "assets/icons/checked.png" alt=""/> 
                        <p> Elevate Your Digital Presence with Seamless Web Solutions </p>
                      </div>
                    </li>

                    <li data-aos="fade-up" data-aos-duration="1500"> 
                      <div className={styles.infos}>
                        <img src= "assets/icons/checked.png" alt=""/>
                        <p> From concept to deployment, we meticulously design and develop dynamic and scalable web applications with intuitive interfaces, robust backend systems and seamless user experiences</p>
                      </div>

                      <div className={styles.infos}>
                        <img src= "assets/icons/checked.png" alt=""/>
                        <p> Ensuring web-based applications are not just functional, but also user-friendly</p>
                      </div>
                    </li>

                    <li data-aos="fade-up" data-aos-duration="2000"> 
                      <div className={styles.infos}>
                        <img src= "assets/icons/checked.png" alt=""/>
                        <p>Full stack development and sustenance in JAVA (spring, hibernate, tomcat/jetty), .NET </p>
                      </div>
                      <div className={styles.infos}>
                        <img src= "assets/icons/checked.png" alt=""/>
                        <p>Track record of successful projects in reverse logistics ERP, E-commerce, Content Management Systems, Learning Management Systems(LMS),  Inventory, Ware house Management, Healthcare and telemedicine platforms</p>
                      </div>

                    </li>
                  </ul>
            </div>
          </>
            
          );
        case 'Mobile App Development':
          return (
            <>
            <div className={styles.fadeUp}>
                  <ul>
                    <li data-aos="fade-up" data-aos-duration="1000">  
                      <div className={styles.infos}>
                          <img src= "assets/icons/checked.png" alt=""/>
                          <p> Native App Development - Provides Endless Possibilities</p>
                      </div>
                      <div className={styles.infos}>
                          <img src= "assets/icons/checked.png" alt=""/>
                          <p> Our native app development services provide flawless performance, an unrivaled user experience, and optimum functionality.</p>
                      </div>
                      <div className={styles.infos}>
                          <img src= "assets/icons/checked.png" alt=""/>
                          <p>  Our native apps, which are tailored exclusively for the iOS and Android platforms, ensure that your business reaches its maximum potential.</p>
                     </div>
                    </li>
                    <li data-aos="fade-up" data-aos-duration="1500"> 
                        <div className={styles.infos}>
                            <img src= "assets/icons/checked.png" alt=""/>
                            <p> Hybrid Apps - Where Innovation Meets Affordability</p>
                        </div>
                        <div className={styles.infos}>
                            <img src= "assets/icons/checked.png" alt=""/>
                            <p> Accept the power of hybrid app creation, which combines cutting-edge technologies with cost-effectiveness.</p>
                        </div>
                        <div className={styles.infos}>
                            <img src= "assets/icons/checked.png" alt=""/>
                             <p> Our team creates hybrid applications that work smoothly across many platforms, giving you the best of both worlds: native performance and cross-platform compatibility.</p>
                        </div>
                    </li>
                    <li data-aos="fade-up" data-aos-duration="2000"> 
                      <div className={styles.infos}>
                        <img src= "assets/icons/checked.png" alt=""/>
                        <p> Elevate User Engagement with Progressive Web Apps (PWAs)</p>
                      </div>
                      <div className={styles.infos}>
                          <img src= "assets/icons/checked.png" alt=""/>
                          <p> Progressive Web Apps combine the finest characteristics of webpages and mobile applications. </p>
                      </div>
                      <div className={styles.infos}>
                          <img src= "assets/icons/checked.png" alt=""/>
                          <p> A few advantages include lightning-fast loading, offline capabilities, and an easy-to-use UI. PWAs can help your business by increasing user engagement.</p>
                      </div>
                
                    </li>
                  </ul>
            </div>
            </>
          
          );
        case 'Product Engineering Services':
          return (
            <>
              <div className={styles.fadeUp}>
                  <ul>
                    <li data-aos="fade-up" data-aos-duration="1000"> 
                      <div className={styles.infos}>
                            <img src= "assets/icons/checked.png" alt=""/>
                            <p>Strategic Solutions, Seamless Execution</p>
                      </div>
                      <div className={styles.infos}>
                            <img src= "assets/icons/checked.png" alt=""/>
                            <p>Trust us to turn your ideas into strategic solutions</p>
                      </div>
                      <div className={styles.infos}>
                            <img src= "assets/icons/checked.png" alt=""/>
                            <p>Seamless execution from inspiration to deployment by combining technical expertise and industry knowledge</p>
                      </div>
                    </li>

                    <li data-aos="fade-up" data-aos-duration="1500"> 
                        <div className={styles.infos}>
                              <img src= "assets/icons/checked.png" alt=""/>
                              <p> Your Vision, Our Expertise: Crafting Exceptional Product solutions</p>
                        </div>
                        <div className={styles.infos}>
                              <img src= "assets/icons/checked.png" alt=""/>
                            <p> Our dedicated team combines technical expertise and creativity to craft solutions that not only meet your needs today but also anticipate the challenges of tomorrow.</p>
                        </div>      
                    </li>

                    <li data-aos="fade-up" data-aos-duration="2000"> 
                      <div className={styles.infos}>
                          <img src= "assets/icons/checked.png" alt=""/>
                          <p> We don’t just engineer products; we craft experiences that resonate with users</p>
                      </div>
                      <div className={styles.infos}>
                          <img src= "assets/icons/checked.png" alt=""/>
                          <p> We bring a holistic approach to product development in enhancing usability or optimizing performance</p>
                      </div>
                    </li>
                  </ul>
              </div> 
            </>
          );
        case 'Consultancy':
          return (
            <>
            <div className={styles.fadeUp}>

                  <ul>
                    <li data-aos="fade-up" data-aos-duration="1000"> 
                      <div className={styles.infos}>
                            <img src= "assets/icons/checked.png" alt=""/>
                            <p>  Navigating Success Together: Your Trusted Software Consulting Partner</p>
                      </div>
                      <div className={styles.infos}>
                            <img src= "assets/icons/checked.png" alt=""/>
                            <p>  Partner with us for tailored software consulting services that drive growth and technological solutions</p>
                      </div>
                      <div className={styles.infos}>
                            <img src= "assets/icons/checked.png" alt=""/>
                            <p> Our experts bring strategic insights, technological expertise, and a passion for problem-solving to every project</p>
                      </div>
                    </li>
                    <li data-aos="fade-up" data-aos-duration="1500"> 
                        <div className={styles.infos}>
                              <img src= "assets/icons/checked.png" alt=""/>
                              <p> Strategic Insights, Real Results: Your Pathway to Software Success</p>
                        </div>
                        <div className={styles.infos}>
                              <img src= "assets/icons/checked.png" alt=""/>
                              <p>  Empoweryour business with our strategic software consulting services</p>
                        </div>
                        <div className={styles.infos}>
                              <img src= "assets/icons/checked.png" alt=""/>
                              <p>  Our team offers actionable insights and innovative solutions to transform your software initiatives and align seamlessly with your business goals</p>
                        </div>
                    </li>
                    <li data-aos="fade-up" data-aos-duration="2000"> 
                        <div className={styles.infos}>
                              <img src= "assets/icons/checked.png" alt=""/>
                            <p>  Customized Solutions, Unparalleled Expertise: Your Software Consulting Destination</p>
                        </div>
                        <div className={styles.infos}>
                              <img src= "assets/icons/checked.png" alt=""/>
                            <p>  Streamline your processes, Enhance efficiency and Boost productivity</p>
                        </div>
                        <div className={styles.infos}>
                              <img src= "assets/icons/checked.png" alt=""/>
                            <p> Experience the difference of working with consultants who address your specific challenges, providing tailored recommendations and hands-on support</p>
                        </div>

                    </li>
                  </ul>
              </div>
          </>
          );
      }
    };

    const [selectedContent, setSelectedContent] = useState('partnership'); // State to track selected content
    const clickHandler = (content) => {
      setSelectedContent(content);
    };
    const getContent = () => {
      switch (selectedContent) {
        case 'partnership':
          return (
            <>
            <div className={styles.fadeUp}>
                 <h1 >Partnership</h1>
                 <ul>
                   <li><img src= "assets/icons/checked.png" alt=""/>Joint Bid - RFPs, Solution Architecture </li>
                   <li><img src= "assets/icons/checked.png" alt=""/>Joint Go-To-Market Strategy - Roadmap, Training    </li>
                   <li><img src= "assets/icons/checked.png" alt=""/>Engagement Partner - Project deal, Management and Delivery   </li>
                 </ul>
             </div>
             <img data-aos="fade-left" data-aos-duration="1500" data-aos-once="true" src="assets/partnership.png" alt="" />  
           </>
            
          );
        case 'collaboration':
          return (
            <>
            <div className={styles.fadeUp}>
                  <h1 >Collaboration</h1>
                  <ul>
                    <li><img src= "assets/icons/checked.png" alt=""/>Project involving Client Product solution  </li>
                    <li><img src= "assets/icons/checked.png" alt=""/>Train Resources on Client Product   </li>
                    <li><img src= "assets/icons/checked.png" alt=""/>Project Management and Delivery   </li>


                  </ul>
              </div>
              <img data-aos="fade-left" data-aos-duration="1500" data-aos-once="true" src="assets/collaboration.png" alt="" />  
            </>
           
          );
        case 'fp':
          return (
            <>
              <div className={styles.fadeUp}>
                  <h1 >Fixed Price - FP</h1>
                  <ul>
                    <li><img src= "assets/icons/checked.png" alt=""/>Project Management and Delivery  </li>
                    <li><img src= "assets/icons/checked.png" alt=""/>Milestone Billing  </li>

                  </ul>
              </div>
              <img data-aos="fade-left" data-aos-duration="1500" data-aos-once="true" src="assets/fixed-price.png" alt="" />  
            </>
          );
        case 'tm':
          return (
            <>
            <div className={styles.fadeUp}>
                <h1 >T&M</h1>
                <ul>
                  <li><img src= "assets/icons/checked.png" alt=""/>Project Management and Delivery  </li>
                  <li><img src= "assets/icons/checked.png" alt=""/>Monthly Billing  </li>

                </ul>
            </div>
            <img data-aos="fade-left" data-aos-duration="1500" data-aos-once="true" src="assets/TM.png" alt="" />    
          </>
          );
      }
    };
    const getNavBarColorClass = () => {
      switch (selectedContent) {
        case 'tm':
          return styles.navBarColorTM;
        case 'fp':
          return styles.navBarColorFP;
        case 'partnership':
          return styles.navBarColorPartnership;
        case 'collaboration':
          return styles.navBarColorCollaboration;
        default:
          return styles.defaultNavBarColor;
      }
    };  
    const scrollWidthOffset = (el) => {
      const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
      const yOffset = -80; 
      window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
  }

    return (
      <div>
        <main className={styles.bnr} id='bnr' style={{position:"relative"}}>
          <div className='container'>
            <div className={styles.bnr_wrap}>
              <div  className={styles.info}>
                <h2>Qream Solutions</h2>
                <h1 data-aos="fade-up" data-aos-duration="1000" >Convert your Business  ideas into  <b/>Solutions</h1>
                <div className={styles.pera} data-aos="fade-up"data-aos-duration="1000">
                  <img src= "assets/icons/checked (2).png" alt=""/>
                   <p> {bnrInfo.paragraph}</p>
                </div>
              
                <div className={styles.pera} data-aos="fade-up" data-aos-duration="1000">
                   <img src= "assets/icons/checked (2).png" alt=""/>
                   <p> Custom solutions for Business needs and growth</p>

                </div>
                  {/* <div className={styles.btn}data-aos="fade-up"data-aos-duration="1000" >
                      <Link to="">{bnrInfo.buton}</Link> 
                  </div> */}
              </div>
              <div className={styles.hero_img} data-aos="fade-left"data-aos-duration="1000">
                <img src='assets/first-pic.jpg' alt='bnr'/>
              </div>
            </div>
          </div>
        </main>
        {/*-------------- Banner Section End ----------------*/}
        
        {/* ------------- Services Section Start -------------- */}
        <section className={styles.service} style={{position:"relative"}} id="services" >
       
          <div className='container'>
            <div className={styles.desktop}>
              <div className={styles.heading}>
                    <h1> Our  <span>Services</span> </h1>
                    {/* <p>At Qream, we're dedicated to turning dreams into reality. Our services are designed to bring your wildest visions to life, whether it's through immersive events, personalized experiences, or unique products. Discover the magic of Qream and embark on a journey where dreams meet reality.</p> */}
              </div>
              <div className={styles.wrap}>
                    {services.map((ele)=>{
                      return(
                        <div className={styles.item} data-aos="fade-up" data-aos-duration="1000" onClick={() => click(ele.name)}>
                          <img src={ele.img} alt=''/>
                          <h2 data-aos="fade-up">{ele.name}</h2>
                          <ul>
                            {ele.info.map(i=> <li><img src= "assets/icons/checked.png" alt=""/><p>{i.li} </p></li>)}
                          </ul>
                        </div>
                      )
                    })}

              </div>
            </div>
            <div className={styles.mobile} >
              <div className={styles.heading}>
                    <h1> Our  <span>Services</span> </h1>
                    <ul>
                      {services.map(ele=><li><i class="fa-solid fa-circle"></i> {ele.name}</li>)}
                    {/* <li> <i class="fa-solid fa-circle"></i> IoT s</li> */}
                  </ul>
              </div>
              <Slider {...service}>
                    {services.map((ele)=>{
                      return(
                        <div className={styles.wrap}>
                        <div className={styles.item} data-aos="fade-up" data-aos-duration="1000" onClick={() => click(ele.name)}>
                          <img src={ele.img} alt=''/>
                          <h2 data-aos="fade-up">{ele.name}</h2>
                          <ul>
                            {ele.info.map(i=> <li><img src= "assets/icons/checked.png" alt=""/><p>{i.li}</p></li>)}
                          </ul>
                        </div>
                       </div>
                      )
                    })}

              </Slider>
            </div>
          </div>
      
        </section>

        {/* ------------- Services Section End -------------- */}
        <section className={styles.solutions}  id="solutions" >
          <div className='container'>
            <div className={styles.heading}>
                  <h1 data-aos="fade-up" data-aos-once="true">Our Solutions </h1>
                  <ul>
                      {solutionMobile.map(ele=><li><i class="fa-solid fa-circle"></i> {ele.name}</li>)}
                    {/* <li> <i class="fa-solid fa-circle"></i> IoT s</li> */}
                  </ul>
            </div>
            <div className={styles.desktop}>
              <div className={styles.wrap}>
                    {solutions.map((ele)=>{
                      return(
                        <>
                          <div className={styles.item} data-aos="flip-left" data-aos-duration="1000" onClick={() => clicks(ele.name)}  offset={70}duration={0} >
                          {ele.img && <img src={ele.img} alt='' />}
                          {ele.info && ele.name && 
                            <div className={styles.info}>
                                {ele.name &&  <h2>{ ele.name}</h2> }
                                {ele.info && <ul>{ele.info.map(i=> <li><img src= "assets/icons/checked.png" alt=""/><p>{i.li} </p></li>)}</ul>}
                            </div>
                          }
                          </div>
                          </>
                      )
                    })}

                    {solutionsTwo.map((ele)=>{
                      return(
                        <>
                          <div className={styles.item} id="erp" data-aos="flip-left" data-aos-duration="1000" onClick={() => clicks(ele.name)}  offset={70}duration={0} >
                          {ele.img && <img src={ele.img} alt='' />}
                          {ele.info && ele.name && 
                            <div className={styles.info}>
                                {ele.name &&  <h2>{ ele.name}</h2> }
                                {ele.info && <ul>{ele.info.map(i=> <li><img src= "assets/icons/checked.png" alt=""/><p>{i.li} </p></li>)}</ul>}
                            </div>
                          }
                          </div>
                          </>
                      )
                    })}

              </div>
            </div>
            <div className={styles.mobile}>
            <Slider {...solution}>          
                {solutionMobile.map((ele)=>{
                  return(
                    <>
                    <div className={styles.box}>
                    {/* <img  src={ele.imgH} /> */}
                    <div className={styles.item} data-aos="flip-left" data-aos-duration="1000" onClick={() => clicks(ele.name)}  >
                    {/* {ele.imgH && <img className={styles.center_img} src={ele.imgH} alt='' />} */}
                    {ele.img && <img src={ele.img} alt='' />}
                    {ele.info && ele.name && 
                      <div className={styles.info}>
                          {ele.name &&  <h2>{ ele.name}</h2> }
                          {ele.info && <ul>{ele.info.map(i=> <li><img src= "assets/icons/checked.png" alt=""/><p>{i.li}</p></li>)}</ul>}
                      </div>
                    }
                    </div>
                    </div>
                    </>
                  )
                })}
              </Slider>
            </div>
          </div>
        </section>
        {/* {--------------- Why Qreams ----------------} */}

        <section className={styles.hero}>
          <div className='container'>
              <div className={styles.heading}>
                <h1>{heroSec.hed}</h1>
              </div>
       
              <div className={`${styles.domain_wrap} ${styles.desktop}`}>
                    <nav  className={`${styles.navBar} ${getNavBarColorClass()}`}>
                      <ul>
                        <li className={selectedContent === 'partnership' ? styles.active : ''}>
                          <div>
                            <img src="assets/icons/partner.png" alt="" />
                            <Link to="" onClick={() => clickHandler('partnership')}>
                              Partnership
                            </Link>
                          </div>
                          <i className="fa-solid fa-chevron-right"></i>
                        </li>
                        <li className={selectedContent === 'collaboration' ? styles.active : ''}>
                          <div>
                            <img src="assets/icons/collaboration.png" alt="assets/icons/collaboration.png" />
                            <Link to="" onClick={() => clickHandler('collaboration')}>
                              Collaboration
                            </Link>
                          </div>
                          <i className="fa-solid fa-chevron-right"></i>
                        </li>
                      
                        <li className={selectedContent === 'fp' ? styles.active : ''}>
                          <div>
                            <img src="assets/icons/price.png" alt="" />
                            <Link to="" onClick={() => clickHandler('fp')}>
                              Fixed Price - FP
                            </Link>
                          </div>
                          <i className="fa-solid fa-chevron-right"></i>
                        </li>
                        <li className={selectedContent === 'tm' ? styles.active : ''}>
                          <div>
                            <img src="assets/icons/tm.png" alt="" />
                            <Link to="" onClick={() => clickHandler('tm')}>
                              T&M
                            </Link>
                          </div>
                          <i className="fa-solid fa-chevron-right"></i>
                        </li>
                      </ul>
                    </nav>
                    <div className={styles.content_box}>
                    <section className={styles.health}>
                        <div className={styles.wrap}>
                          {getContent()}
                          </div>
                    </section>
                    </div>
              </div>
              <div className={`${styles.domain_wrap} ${styles.mobile}`}>
              <div className="accordion" id="accordionExample">    
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      <img src="assets/icons/partner.png" alt="" />   Partnership
                    </button>
                  </h2>
                  <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    <ul  className='pb-3'>
                      <li><img src= "assets/icons/checked.png" alt=""/>Joint Bid - RFPs, Solution Architecture </li>
                      <li><img src= "assets/icons/checked.png" alt=""/>Joint Go-To-Market Strategy - Roadmap, Training    </li>
                      <li><img src= "assets/icons/checked.png" alt=""/>Engagement Partner - Project deal, Management and Delivery   </li>
                    </ul>
                      <img data-aos="fade-left" className='w-100' data-aos-duration="1500" data-aos-once="true" src="assets/partnership.png" alt="" />  
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingfour">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
                    <img src="assets/icons/collaboration.png" alt="assets/icons/collaboration.png" /> Collaboration
                    </button>
                  </h2>
                  <div id="collapsefour" className="accordion-collapse collapse" aria-labelledby="headingfour" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <ul className='pb-3'>
                        <li><img src= "assets/icons/checked.png" alt=""/>Project involving Client Product solution  </li>
                        <li><img src= "assets/icons/checked.png" alt=""/>Train Resources on Client Product   </li>
                        <li><img src= "assets/icons/checked.png" alt=""/>Project Management and Delivery   </li>
                      </ul>
                      <img data-aos="fade-left" className='w-100' data-aos-duration="1500" data-aos-once="true" src="assets/collaboration.png" alt="" />  

                      </div>
                  </div>
                </div>
                
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    <img src="assets/icons/price.png" alt="" />  Fixed Price - FP
                    </button>
                  </h2>
                  <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    <ul  className='pb-3'>
                      <li><img src= "assets/icons/checked.png" alt=""/>Project Management and Delivery  </li>
                      <li><img src= "assets/icons/checked.png" alt=""/>Milestone Billing  </li>
                    </ul>
                    <img className='w-100' data-aos="fade-left" data-aos-duration="1500" data-aos-once="true" src="assets/fixed-price.png" alt="" />  

                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    <img src="assets/icons/tm.png" alt="" />  T&M
                    </button>
                  </h2>
                  <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    <ul  className='pb-3'>
                      <li><img src= "assets/icons/checked.png" alt=""/>Project Management and Delivery  </li>
                      <li><img src= "assets/icons/checked.png" alt=""/>Monthly Billing  </li>

                    </ul>
                    <img className='w-100' data-aos="fade-left" data-aos-duration="1500" data-aos-once="true" src="assets/TM.png" alt="" />    

                    </div>
                  </div>
                </div>
                  </div>
              </div>
          </div>
        </section>
        
        <section id='serviceexplain' className={styles.explains} >
          <div className='container'>
            <div className={styles.heading}>
              <h1>{servicesContent}</h1>
            </div>
            <div className={styles.content_box}>
              <section className={styles.health}>
                  <div className={styles.wrap}>
                     {serviceContent()}
                  </div>
               </section>
            </div>
            <div className={styles.backButton}>
              <NavHashLink  to="/#services" scroll={el => scrollWidthOffset(el)}> <i className='fa fa-left-arrow'></i> Back to Services </NavHashLink>
            </div>
          </div>
        </section>

        <section id='solutionexplain' className={styles.solutionexplains}  >
          <div className='container'>
            <div className={styles.heading}>
            <h1>{solutionContent}</h1> 
            </div>
            <div className={styles.content_box}>
              <section className={styles.health}>
                  <div className={styles.wrap}>
                     {solutionsContent()}
                  </div>
               </section>
             
               
            </div>
            <div className={styles.backButton}>
              <NavHashLink  to="/#solutions" scroll={el => scrollWidthOffset(el)}> <i className='fa fa-left-arrow'></i> Back to Solutions </NavHashLink>
            </div>
          </div>
        </section>        
      </div>
    )
  }

export default Home




