export const navbarMenu = [ 
    { url: "/home", name: "Home", },
    {
        url: "/#services", name: "Services", dropDwon: [
            { url: "/#services", name: "Web App Development", img: "assets/icons/web.png", },
            { url: "/#services", name: "Mobile App Development", img: "assets/icons/mobile.png", },
            { url: "/#services", name: "Product Engineering Services", img: "assets/icons/product.png", },
            { url: "/#services", name: "Consultancy", img: "assets/icons/consultation.png", },

        ],
        descriptions: [
            { head: "Services", pera: "Qream provides a comprehensive suite of services, including web and mobile app development, product engineering, and expert consultancy. Our dedicated team combines innovation, quality assurance, and timely project delivery to empower your digital initiatives and drive your success in a rapidly evolving technological landscape." }
        ]
    },
    {
     id:"/#solutions",   url: "/#solutions", name: "Solutions", dropDwon: [
            { url: "/#solution", name: "Healthcare Solutions", img: "assets/icons/health.png", },
            { url: "/#solution", name: "Technology Solutions", img: "assets/icons/technology.png", },
            { url: "/#erp", name: "ERP Solutions", img: "assets/icons/erp.png", },
            { url: "/#erp", name: "Custom Solutions", img: "assets/icons/custom.png", },

        ],
    },
    // {url:"/", name:"Domains " ,dropDwon : [
    //     {url: "/" ,name:"Healthcare" ,  img: "assets/icons/health.png" ,},
    //     {url: "/" ,name:"IoT" ,  img: "assets/icons/iot.png" ,},
    //     {url: "/" ,name:"E-Commerce" ,  img: "assets/icons/ecom.png" ,},
    //     {url: "/" ,name:"Manufacturing",  img: "assets/icons/manufacture.png" ,},

    // ],
    // descriptions : [
    //     {head:"Domains", pera: "Qream is distinguished by its proficiency in delivering bespoke solutions across an array of discerning domains, encompassing the intricate realms of healthcare, IoT, e-commerce, and manufacturing. Our multifaceted expertise traverses diverse industries, characterized by an unwavering commitment to innovation, rigorous quality standards, and the punctual orchestration of tailored solutions. We empower your digital endeavors within these dynamic sectors, poised to ensure your ascendancy in an ever-evolving technological milieu."}
    // ]},
    {
        url: "/usecase", name: "Use Cases"
    },
    {
        url: "/about", name: "About Us"
    },

]

export const bnrInfo = {
    heading:"Convert your Business ideas into  Solutions",
    paragraph:"Software Development as a Service (SDaaS) for Enterprise web applications, Mobile applications and Product engineering services",
    buton:"Download QreaM brochure"
}


export const qream_data = [
    {img:"assets/why-qream-1.png",name:"Trusted management experience" , point:[
        // {li:"Management team with Industry experience in multitude of projects"},
        // {li:"Consistent & reliable services"},
        // {li:"Customer satisfaction"},
        // {li:"Technology expertise"},
        {li:"100+ years of  experience in Business Leadership, Solution Engineering, Software Development and Management, Product development and Client liaisoning        "},
        {li:"Technology Expertise - Supply chain & logistics ERP, Healthcare, Manufacturing, Data Migration, E-commerce, Cloud Services, Data Engineering, Agile process, Digital Transformation, Telecom, VoIP, Contact center"},

    ],dursn: ""},
    {img:"assets/why-qream-2.png",name:"Skilled resources" , point:[
        {li:"150+ years of experience in .NET, Java full stack on SQL & No-SQL databases "},
        {li:" Technology Expertise - Manufacturing, Supply chain & logistics ERP, E-commerce, Content Management Systems, Learning Management Systems(LMS), Inventory, Ware house Management, Healthcare and telemedicine platforms"},

        // {li:"Hands-on experience"},
        // {li:"Quality & On-time delivery        "},
        // {li:"Custom Solutions        "},
        // {li:"Well co-ordinated and managed team        "},

    ]},
    {img:"assets/why-qream-3.png",name:"Professional Infrastructure" , point:[
 

        {li:"Well-equipped for in-house project development        "},
        {li:"Best-in-class Infra        "},

    ]},

]

export const exp_info = {
    subhed:"The #1 Solutions Provider",
    head: "Discover Qreams Tech Innovations",
}



export const heroSec = {
    hed:"Engagement Models for Qream Services & Solutions"
}
export const heroData = [
    {name: "T&M", para: [
        {li:"Project Management and Delivery        "},
        {li:"Monthly Billing        "},

    ]},
    {name: "Fixed Price - FP", para:[
        {li:"Project Management and Delivery        "},
        {li:"Milestone Billing        "},
    ]},
    {name: "Partnership", para:[
        {li:"Joint Bid - RFPs, Solution Architecture"},
        {li:"Joint Go-To-Market Strategy - Roadmap, Training  "},
        {li:"Engagement Partner - Project deal, Management and Delivery"},

    ]},
    {name: "Collaboration", para:[
        {li:"Project involving Client Product solution "},
        {li:"Train Resources on Client Product"},
        {li:"Project Management and Delivery"},

    ]},

]

export const footerData = [
    {type: "Services" , subData: [
        {url:"/#services", li:"Web App Development" },
        {url:"/#services", li:"Mobile App Development"},
        {url:"/#services", li:"Product Engineering Services"},
        {url:"/#services", li:"Consultancy"},
    ]},
    {type: "Solutions" , subData: [
        {url:"/#helth",li:"Healthcare "},
        {url:"/#tech",li:"Technology "},
        {url:"/#erp",li:"ERP "},
        {url:"/#custom",li:"Custom "},
    ]},   

]


export const aboutInfo =[
    {img: "assets/vision-pic.jpg", name:"Vision",info:"To be a leading Software Development as a Service (SDaaS) provider by offering services and solutions involving open source and technological solutions"},
    {img: "assets/mission.png", name:"Mission",info:"Enable client's business growth with on-time and qualitative Project delivery, leveraging best-in-class infrastructure with dedicated and skilled resources "},
    {img: "assets/values.png", name:"Values",info:"Integrity, Transparency and Customer satisfaction"},

]
export const  services = [
    {name:"Web App Development",info:[
        {li:"Elevate Your Digital Presence with Seamless Web Solutions"},
        {li:"Concept to deployment services"},
        {li:"Design and development of dynamic and scalable web applications"},
        {li:"Full stack development"},

    ], img: "assets//icons/web.png" },
    {name:"Mobile App Development",info:[
        {li:"Tailored exclusively for the iOS and Android platforms:        "},
        {li:"Native App Development        "},
        {li:"Hybrid Apps: Where Innovation Meets Affordability"},
        {li:"Elevate User Engagement with Progressive Web Apps (PWAs)        "},
    ],  img: "assets/icons/mobile.png"},

    {name:"Product Engineering Services",info:[
        {li:"Turn your ideas into Strategic Solutions and Seamless Execution"},
        {li:"Leverage Technical expertise and Industry knowledge        "},

    ], img: "assets/icons/product.png"},
    {name:"Consultancy",info:[
        {li:"Software consulting services that drive growth and technological solutions        "},
        {li:"RFPs, Due Diligence, PoCs (proof of concepts), Solution Architecture"},
        {li:"Strategic Insights"},
        {li:"Customised Solution"},


    ], img: "assets/icons/consultation.png",},
]

export const  solutionMobile = [
    {name:"Healthcare Solutions",info:[
        {li:"Simplified workflows with enhanced collaboration        "},
        {li:"State-of-the-art telemedicine platforms connecting patients with healthcare providers remotely        "},
        {li:"Advanced diagnostic tools enabling medical assessments"},
        {li:"Solutions  for  point-of-care devices (POCD), Home Care, Long term Care, Remote care, Digitization of healthcare services"},
        {li:"Interfacing / Integration with medical devices"},


    ], img: "assets/icons/health.png" },
  
    {name:"Technology Solutions",info:[
       
        {li:"For Innovative startup or an established enterprise seeking digital evolution        "},
        {li:"Technical solutions enabling organizations to stay agile and competitive"},
        {li:"Committed to implementation, support, real-time monitoring and proactive maintenance        "},
        {li:"Tailor made solutions in internet & things (IoT) over Bluetooth Low Energy (BLE), Bluetooth, WIFI, etc."},

    ], img: "assets/icons/technology.png"},
    {name:"ERP Solutions",info:[
        {li:"Streamline entire operation by aligning your goal with ERP solution        "},
        {li:"Supply chain and logistics        "},
        {li:"Manufacturing"},
        {li:"Inventory"},


    ], img: "assets/icons/erp.png"},
    {name:"Custom Solutions",info:[
        {li:"Customized solutions for Qream Services and Solutions        "},
        {li:"From responsive user interfaces to complex backend algorithms        "},
        {li:"Conceptualize the requirements and transform into digital solution"},
        {li:"Process Automation & Digital Transfromation"},

    ], img: "assets/icons/custom.png"},

]

export const  solutions = [
    {name:"Healthcare Solutions",info:[
        {li:"Simplified workflows with enhanced collaboration        "},
        {li:"State-of-the-art telemedicine platforms connecting patients with healthcare providers remotely        "},
        {li:"Advanced diagnostic tools enabling medical assessments"},
        {li:"Solutions  for  point-of-care devices (POCD), Home Care, Long term Care, Remote care, Digitization of healthcare services"},
        {li:"Interfacing / Integration with medical devices"},


    ], img: "assets/icons/health.png" },
  
    {name:"Technology Solutions",info:[
       
        {li:"For Innovative startup or an established enterprise seeking digital evolution        "},
        {li:"Technical solutions enabling organizations to stay agile and competitive"},
        {li:"Committed to implementation, support, real-time monitoring and proactive maintenance        "},
        {li:"Tailor made solutions in internet & things (IoT) over Bluetooth Low Energy (BLE), Bluetooth, WIFI, etc."},

    ], img: "assets/icons/technology.png"},

]

export const  solutionsTwo = [
    {name:"ERP Solutions",info:[
        {li:"Streamline entire operation by aligning your goal with ERP solution        "},
        {li:"Supply chain and logistics        "},
        {li:"Manufacturing"},
        {li:"Inventory"},


    ], img: "assets/icons/erp.png"},
    {name:"Custom Solutions",info:[
        {li:"Customized solutions for Qream Services and Solutions        "},
        {li:"From responsive user interfaces to complex backend algorithms        "},
        {li:"Conceptualize the requirements and transform into digital solution"},
        {li:"Process Automation & Digital Transfromation"},

    ], img: "assets/icons/custom.png"},
]
export const useCase = [
    {heading:"Sleep Tracking Application", info:[
        {list:"Boot Strapping sleep tracking platform for US based start up"},
        {list:"The app helps to measure sleep habits and improve sleep quality"},
        {list:"App records any sounds(snoring ,talking or cough), track light, deep, and REM sleep and identify when you wake up during the night"},
        {list:"The app articulates a sleep score to attach a reference indicator to your sleep quality to help with setting goals fo better sleep "},
        {list:"The application can also collect sleep data from a select devies "}
    ],
    imges:"assets/Sleep-track-app.jpg"
    
    },
    {heading:"IoT Healthcare – Temperature Measurement with BLE", info:[
        {list:"Developed an Android &IOSApplicationthat interfaces with InnovativeIoTdevice over Bluetooth Lowprofile"},
        {list:"The Application deciphers informationreceived over BLE profile andimplements business logic todisplayparameters on the applications"},
        {list:"The application can be utilizedbydoctors, clinics or at hospitals capableofmeasuring multiple devices. Flexibilitytodefine range of measurement andalarming the physicians areaddedfeatures"},
        {list:"The application has an interfacetotheshare the captured data over cloud"},
       
    ],
    imges:"assets/IOT-Helathcare.jpg"
    
    }, 
    {heading:"Shop Floor Control System - SFCS", info:[
        {list:"Features - Production Workflow Automation, Resource Allocation, Comprehensive Reporting, InventoryManagement, Scalability, Integration with ERP (SAP)"},
        {list:"Advantages - Streamlined Operations, Real-time Visibility, Resource Optimization, EnhancedQualityControl"},
        {list:"Value proposition - Increased Efficiency, Data driven Decisions, Quality Improvement, Cost Reduction,Compliance, Customer Satisfaction"},
    ],
    imges:"assets/SFCS.jpg"
    
    },
    {heading:"Traffic Signals Management – Live Dashboard Application", info:[
        {list:"Android application for providing the Live status on various parameters of trafficsignalsof a metro city in India"},
        {list:"The application provides Live status report on mode of operation, camera, connectivity, Lamps, complaints etc"},
        {list:"The reports are categorized and selectively accessed by Maintenance Engineers, Fieldsupport Engineers, Police officers, Officers of municipal corporation, etc."},
    ],
    imges:"assets/Traffic-management.jpg"
    
    },

]
export const  Domains = [
    {name:"Healthcare",info:"Our holistic approach and skilled tech teams optimize business value through suitable solutions, scalability, implementation,", img: "assets/hero1.jpg" },
    {name:"IoT",info:"Our holistic approach and skilled tech teams optimize business value through suitable solutions, scalability, implementation,",  img: "assets/hero1.jpg"},
    {name:"E-commerce",info:"Our holistic approach and skilled tech teams optimize business value through suitable solutions, scalability, implementation,", img: "assets/hero1.jpg"},
    {name:"Manufacturing",info:"Our holistic approach and skilled tech teams optimize business value through suitable solutions, scalability, implementation,", img: "assets/hero1.jpg",},
]
export const team =[
    { name:"Prakash S" , info:[
        {infoLi:"25+ years of Experience in Business Leadership, Managing multiple customer portfolios"},
        {infoLi:"ERP & Healthcare systems, VoIP, Telecom         "},
        {infoLi:"Software Professional with proven track records of managing projects from concept to completion"},

    ], subTitel: "Managing Director" },

    // { name:"Kumar S    " , info:[
    //     {infoLi:"20 years of experience in ERP Systems, Data Migration, Software Development & Management"},
    //     {infoLi:"Agile process, Digital Transformation, Business Analysis"}

    // ], subTitel: "Delivery Head"},

    // { name:"Kiran H     " , info:[
    //     {infoLi:"25+ years of experience in Business Development, Bid Management, Pre-Sales, Go-To-Market Strategy"},
    //     {infoLi:"Cloud Services, Data Engineering, Spark, Telecom, Contact Center        "},
    //     {infoLi:"Multi-million-dollar P&L Program Management, Solution Engineering,  Consultancy, PoCs"},

    // ], subTitel: "Sr. Manager - Business Growth"},

    
]

export const contactInfo = [
    {head:"Contact Information",infos:[
        {info:"+91-63645 01737 " , icon: "fa fa-phone"},
        {info:"info@qreams.com" , icon: "fa-solid fa-envelope"},
        
    ]},
    {head:"Business Hours",infos:[
        {info:"Monday - Friday: 9am - 6pm" ,icon:"fa-solid fa-clock"},
    ]},
    {
    head:"Address",
    subhed:"Qream Solutions Pvt Limited ",
    infos:[
        {info:"No 1, #302, 3rd floor, Navarathna Gardens Kanakapura Rd, Doddakallasandra Bangalore-560062, Karnataka, India.",icon: "fa-solid fa-location-dot"},
    ]},
]

export const servicsesExplain=[
    {para:"Elevate Your Digital Presence with Seamless Web Solutions "}
]