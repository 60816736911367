import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from "../usecase/Usecase.module.scss"
import { useCase } from '../../Config/config'
export class Usecase extends Component {
  static propTypes = {

  }

  render() {
    return (
        <div> 
            <section className={styles.contact}>
                <div className='container'>
                    <div className={styles.bnr_info}>
                        <h2>Qream Solutions</h2>
                        <h1>Use Cases</h1>
                        <p><a href='/'>Home</a></p>
                    </div>
                </div>
            </section>
            <section className={styles.usecase} id="/#usecase">
                <div className='container'>
                    {useCase.map((ele)=>{
                    return(
                        <div className={styles.flex_wrap}>
                            <div className={styles.content_info}>
                            <h2>{ele.heading}</h2>
                            <ul>
                                {ele.info.map(i => <li> <i className="fa-solid fa-caret-right"></i>  <p>{i.list}</p></li>)}
                            </ul>
                            </div>
                            <div className={styles.img}>
                            <img src={ele.imges}  />
                            </div>
                        </div>
                    )
                    })}
                </div> 
        </section>    
      </div>
    )
  }
}

export default Usecase
